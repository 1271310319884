<template>
  <section>
    <div class="bg-white rounded-md shadow-md p-4 w-full">
      <h1>Aqui va el historial</h1>
    </div>
  </section>
</template>
<script>
  import { onMounted, computed } from 'vue'
  import { useOrdenesStore } from '../../../../stores/ordenes.store'
  // import { useRoute } from 'vue-router'
  export default {
    name: 'historialOrdenDeVenta',
    setup () {
      const ordenesStore = useOrdenesStore()
      // const route = useRoute()
      const historial = computed(() => ordenesStore._historial_documento)
      onMounted(() => {
        // ordenesStore.getHistorialOv(route.params.id)
      })
      return {
        historial
      }
    }
  }
</script>
